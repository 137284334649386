<template>
  <div>
    <div class="flex flex-col w-full py-2 text-center">
      <h1 class="text-2xl font-bold text-gray-700 sm:text-3xl">
        Reestablece tu contraseña
      </h1>
    </div>
    <div class="flex flex-col py-2 text-left">
      <el-form
        ref="resetForm"
        :model="resetForm"
        hide-required-asterisk>
        <el-form-item label="Contraseña" prop="password">
          <el-input
            :type="showPassword ? 'text' : 'password'"
            v-model="resetForm.password"
            autocomplete="off">
              <span
                class="el-input__icon cursor-pointer text-sm hover:cursor-pointer flex items-center"
                :class="showPassword ? 'text-red-400 hover:text-red-400': 'text-purple-500 hover:text-purple-400'"
                slot="suffix"
                @click="showPassword = !showPassword">
                 <i :class="showPassword ? 'text-xl bx bx-show text-red-500': 'text-xl bx bx-hide'"></i>
              </span>
          </el-input>
        </el-form-item>
        <el-form-item label="Confirma tu Contraseña" prop="passwordConfirm">
          <el-input
            :type="showPasswordConfirm ? 'text' : 'password'"
            v-model="resetForm.passwordConfirm"
            autocomplete="off">
              <span
                class="el-input__icon cursor-pointer text-sm hover:cursor-pointer flex items-center"
                :class="showPasswordConfirm ? 'text-red-400 hover:text-red-400': 'text-purple-500 hover:text-purple-400'"
                slot="suffix"
                @click="showPasswordConfirm = !showPasswordConfirm">
                 <i :class="showPasswordConfirm ? 'text-xl bx bx-show text-red-500': 'text-xl bx bx-hide'"></i>
              </span>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="w-full flex flex-row" style="justify-content: right;">
        <sa-button
          class="rounded text-bold"
          custom-class="w-full"
          type="primary"
          v-loading="loading"
          element-loading-background="#DDD6FE"
          element-loading-spinner=""
          @click="submit()">
          Enviar
        </sa-button>
      </div>
    </div>
  </div>
</template>
<script>
import SignService from '@/services/SignService';
import error from '@/mixins/error';
import _ from 'lodash';

export default {
  name: 'ResetForm',
  mixins: [error],
  data() {
    return {
      showPassword: false,
      showPasswordConfirm: false,
      token: null,
      loading: false,
      errorMessage: undefined,
      resetForm: {
        password: null,
        passwordConfirm: null,
      },
    }
  },
  mounted() {
    this.token = this.$route.params.token;
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    async submit() {
      if (this.resetForm.password !== this.resetForm.passwordConfirm) {
        this.errorMessage = 'Las contraseñas no coinciden';
        return;
      }
      await this.reset();
    },
    async reset() {
      this.loading = true;
      try {
        await SignService.reset(this.token, { password: this.resetForm.password });
        this.$swal("Todo listo!", "Tu contraseña ha sido actualizada con exito!", "success").then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'login.index' });
          }
        });
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>
